import React, {ReactElement, useState, useEffect} from 'react'
import {Box, Container, Button, AppBar, Toolbar, makeStyles, withStyles} from '@material-ui/core'
import {throttle} from 'lodash'
import avi from '../../assets/images/face-avi.png'

type HeaderProps = {
  className?: string
}

const useStyles = makeStyles({
  navbarWrapper: {
    transitionDuration: "0.2s"
  },
  whiteBackground: {
    backgroundColor: '#fff',
    color: 'black'
  },
  transparentBackground: {
    backgroundColor: "transparent",
    color: "white",
  },
  scrolledMenuButton: {
    color: '#212121'
  },
  topMenuButton: {
    color: '#fff'
  },
  drawer: {
    width: 'auto'
  }
})

const CatalogButton = withStyles({
  root: {
    backgroundColor: '#1c87e5',
    padding: '8px 30px',
    borderRadius: '4px',
    color: '#fff',
    '&:hover, &:focus': {
      backgroundColor: '#fff',
      color: '#1c87e5',
      boxShadow: 'inset 0px 0px 0px 2px #1c87e5',
    }
  }
})(Button)

export default function Header(props: HeaderProps): ReactElement {
  const classes = useStyles();
  const [isNavbarScrolled, setIsNavbarScrolled] = useState<boolean>(false)

  const handleScroll = () => {
    const show = window.scrollY > 400

    if (!isNavbarScrolled) {
      setIsNavbarScrolled(show)
    }
  }

  useEffect(() => {
    document.addEventListener('scroll', throttle(() => {handleScroll()}, 200))
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, []);

  // TODO: Add Logo
  return (
    <AppBar elevation={0} position="fixed" className={[
      classes.navbarWrapper,
      isNavbarScrolled ? classes.whiteBackground : classes.transparentBackground].join(' ')
    }>
      <Container>
        <Toolbar>
          <img src={avi} height="54x"/>
          <Box ml="auto">
          {isNavbarScrolled &&
            <CatalogButton 
              href='https://player.beatstars.com/?storeId=30975'
            >Beat Catalog</CatalogButton>}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};