import React from "react"
import Layout from '../components/Layout' 
import {Box, Container, Button, Typography, Grid,  makeStyles, withStyles} from '@material-ui/core'
import './index.css'
import logo from '../assets/images/aman-logo.png'
import LicenseBox from '../components/Home/LicenseBox'

const useStyles = makeStyles({
  heroSection: {
    backgroundColor: '#1c87e5',
    minHeight: '50vh'
  },
  heroLogo: {
    height: '170px',
    objectFit: 'cover'
  },
  sectionHeaderTypography: {
    fontSize: '24px',
    fontWeight: '700',
    color: '#1c87e5',
  },
  paper: {
    padding: '24px'
  }
})

const WhiteButton = withStyles({
  root: {
    backgroundColor: '#fff',
    color: '#1c87e5',
    borderRadius: '4px',
    padding: '8px 30px',
    '&:hover, &:focus': {
      backgroundColor: '#1c87e5',
      color: '#fff',
      boxShadow: 'inset 0px 0px 0px 2px #fff',
    }
  },
  
})(Button)

export default function Home() {
  const classes = useStyles();

  return (
    <Layout>
      <Box id='home' display="flex" alignItems="center" justifyContent="center" className={classes.heroSection}>
        <Container>
          <Box display="flex" alignItems="center" justifyContent="center">
            <img src={logo} alt="logo" className={classes.heroLogo}/>
          </Box>
          <Box mt={3} display="flex" alignItems="center" justifyContent="center">
            <WhiteButton 
              variant="contained"
              disableElevation
              href='https://player.beatstars.com/?storeId=30975'            
            >
              Beat Catalog
            </WhiteButton>
          </Box>
        </Container>
      </Box>
      <Box py={8} display="flex" alignItems="center" justifyContent="center">
        <Container>
          <Box mb={6} display="flex" alignItems="center" justifyContent="center" id='beatstore'>
            <Typography className={classes.sectionHeaderTypography}>BEAT STORE</Typography>
          </Box>
          <Box mb={6} display="flex" alignItems="center" justifyContent="center">
            <iframe title="beatstore" src="https://player.beatstars.com/?storeId=30975" width="100%" height="800" style={{maxWidth: '1024px', border: 'none'}}> -- none -- </iframe>
          </Box>
        </Container>
      </Box>
      <Box py={8} display="flex" alignItems="center" justifyContent="center" id='licensing'>
        <Container>
          <Box mb={6} display="flex" alignItems="center" justifyContent="center">
            <Typography className={classes.sectionHeaderTypography}>LICENSES</Typography>
          </Box>
          <Grid container spacing={3} justify="center">
            <LicenseBox license='basic'/>
            <LicenseBox license='pro'/>
            <LicenseBox license='unlimited'/>
          </Grid>
        </Container>
      </Box> 
    </Layout>
  );
}
