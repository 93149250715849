import React from 'react';
import {Grid, Paper, Box, Button, Typography, List, ListItem, withStyles, makeStyles} from '@material-ui/core'

type LicenseBoxProps = {
  license: string;
}

const useStyles = makeStyles({
  paper: {
    padding: '24px'
  }
})

const BlueButton = withStyles({
  root: {
    backgroundColor: '#1c87e5',
    padding: '8px 30px',
    borderRadius: '4px',
    color: '#fff',
    '&:hover, &:focus': {
      backgroundColor: '#fff',
      color: '#1c87e5',
      boxShadow: 'inset 0px 0px 0px 2px #1c87e5',
    }
  }
})(Button)

const licenseOptions = {
  basic: {
    title: 'Basic License',
    price: '$39.97',
    feature1: 'Untagged MP3 + WAV',
    feature2: 'Make up to $5,750',
    feature3: '5000 unit sales',
    feature4: '250,000 audio and video streams'
  },
  pro: {
    title: 'Pro License',
    price: '$69.97',
    feature1: 'Untagged WAV + Stems',
    feature2: 'Make up to $11,500',
    feature3: '10000 unit sales',
    feature4: '500,000 audio and video streams'
  },
  unlimited: {
    title: 'Unlimited License',
    price: '$149.97',
    feature1: 'Untagged WAV + Stems',
    feature2: 'Make unlimited $$$',
    feature3: 'Unlimited unit sales',
    feature4: 'Unlimited audio and video streams'
  }
}

const LicenseBox = (props: LicenseBoxProps): React.ReactElement => {
  const classes = useStyles();
  const license = licenseOptions[props.license] || licenseOptions['basic']

  return (
    <Grid item md={4}>
      <Paper elevation={3} className={classes.paper}>
        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
          <Typography variant="subtitle1">{license.title}</Typography>
          <Typography variant="h2">{license.price}</Typography>
          <List>
            <ListItem alignItems='center'>
              {license.feature1}
            </ListItem>
            <ListItem alignItems='center'>
              {license.feature2}
            </ListItem>
            <ListItem alignItems='center'>
              {license.feature3}
            </ListItem>
            <ListItem alignItems='center'>
              {license.feature4}
            </ListItem>
            <Box mt={2} display="flex" alignItems="center" justifyContent="center">
              <BlueButton href="https://player.beatstars.com/?storeId=30975">Buy Now</BlueButton>
            </Box>
          </List>
        </Box>
      </Paper>
    </Grid>
  )
}

export default LicenseBox