import React, {ReactElement} from 'react';
import Header from './Header';
import Footer from './Footer';
import {Box, makeStyles} from '@material-ui/core'

type LayoutProps = {
  children: ReactElement
}

const useStyles = makeStyles({
  mainContainer: {
    minHeight: "100vh"
  }
})

export default function Layout(props: LayoutProps): ReactElement {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column">
      <Header/>
        <div className={classes.mainContainer}>
          {props.children}  
        </div>
      {/* <Footer/> */}
    </Box>
  )
}